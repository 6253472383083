import s from '@/layouts/AlphaLayout/TwRedFlagModal/TopupModal.module.scss';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { jsNumberForAddress } from 'react-jazzicon';
import React from 'react';
import Avatar from '@/modules/AlphaPWA/Profiles/TradeKey/components/avatar';

const AvatarTwitter = ({
  url,
  address,
  width,
}: {
  url?: string;
  address: string;
  width: number;
}) => {
  return <Avatar url={url} width={width} />;

  // if (url) {
  //   return (
  //     <img
  //       src={url}
  //       alt=""
  //       style={{
  //         borderRadius: '50%',
  //         width: `${width}px`,
  //         height: `${width}px`,
  //         minWidth: `${width}px`,
  //         minHeight: `${width}px`,
  //       }}
  //     />
  //   );
  // }

  // return (
  //   <div className={s.img_default}>
  //     <Jazzicon diameter={width} seed={jsNumberForAddress(address)} />
  //   </div>
  // );
};

export default AvatarTwitter;
