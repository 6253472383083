const IOSIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.53006 1.93881C8.02881 1.35786 8.7287 0.993777 9.48478 0.921943C9.51154 0.92098 9.53768 0.930263 9.558 0.947955C9.57832 0.965647 9.59133 0.990449 9.59445 1.01743C9.65302 1.79697 9.40607 2.56848 8.90725 3.1644C8.68508 3.44874 8.40547 3.68151 8.08722 3.84705C7.76897 4.01259 7.41946 4.10704 7.06221 4.12409C7.01584 4.12409 6.9703 4.12211 6.92504 4.11902C6.90075 4.11704 6.87785 4.10668 6.86017 4.08967C6.84249 4.07265 6.83109 4.05001 6.82787 4.02551C6.80031 3.64764 6.84831 3.26801 6.96898 2.90942C7.08964 2.55084 7.28049 2.2207 7.53006 1.93881ZM3.01203 12.3934C1.79977 10.6174 1.10312 7.68877 2.19904 5.75926C2.47299 5.26489 2.86826 4.85076 3.34649 4.55706C3.82471 4.26335 4.36964 4.10004 4.92842 4.08298H4.9623C5.42027 4.11101 5.86889 4.22666 6.28451 4.42382C6.5205 4.53966 6.77386 4.61486 7.03419 4.64634C7.28562 4.61198 7.53016 4.53774 7.75887 4.42634C8.23556 4.20383 8.7491 4.0738 9.27295 4.04297C9.34486 4.04297 9.41538 4.04607 9.48369 4.05311C9.9599 4.06947 10.4258 4.19812 10.8445 4.42884C11.2632 4.65956 11.6232 4.98601 11.896 5.38236C11.9039 5.39425 11.9095 5.40764 11.9123 5.42172C11.915 5.4358 11.915 5.45029 11.9123 5.46437C11.9095 5.47845 11.9039 5.49183 11.896 5.50372C11.888 5.51561 11.8779 5.52579 11.866 5.53364L11.8555 5.5401C11.4578 5.79066 11.1286 6.13873 10.8983 6.55222C10.668 6.96572 10.544 7.43135 10.5377 7.90623C10.5395 8.44252 10.6959 8.96654 10.9876 9.41378C11.2794 9.86103 11.6936 10.2119 12.1795 10.4233L12.187 10.4273C12.2109 10.4383 12.23 10.4579 12.2406 10.4823C12.2511 10.5068 12.2524 10.5343 12.2442 10.5597L12.2403 10.5712C12.0313 11.2296 11.7308 11.8543 11.3476 12.4264C10.8367 13.1844 10.2536 14.0449 9.3096 14.0627C8.95134 14.0623 8.59822 13.9762 8.27893 13.8114C7.9444 13.6421 7.57626 13.5523 7.20245 13.5489C6.81018 13.5522 6.42368 13.645 6.07154 13.8204C5.773 13.9691 5.44857 14.0569 5.11668 14.0787H5.06892C4.22956 14.0787 3.61705 13.2818 3.01203 12.3934Z"
        fill="#F8F0AC"
      />
    </svg>
  );
};

export default IOSIcon;
