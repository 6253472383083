import React, { useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import s from './styles.module.scss';
import { CDN_URL_ICONS } from '@/configs';
import { initializeFCM } from '@/services/firebase/messaging';
import { WalletContext } from '@/contexts/wallet-context';
import accountStorage from '@/utils/account.storage';

const NotificationPermissionModal: React.FC = (): React.ReactElement => {
  const { addressL2 } = useContext(WalletContext);
  const [isOpen, setIsOpen] = useState(true);

  const handleGetNotification = async () => {
    if (!addressL2) return;
    accountStorage.setShowedNotification();
    const isEnable = await initializeFCM(addressL2);
    if (isEnable) {
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    accountStorage.setShowedNotification();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="full" isCentered>
      <ModalContent>
        <ModalBody display={'flex'}>
          <div className={s.notificationWrapper}>
            <div className={s.mainContent}>
              <img
                className={s.logo}
                src={`${CDN_URL_ICONS}/ic-notif-per.png`}
                alt="notif"
              />
              <p className={s.description}>
                Be the first to receive and capture alpha in crypto.
              </p>
              <Button onClick={handleGetNotification} className={s.submitBtn}>
                Enable notifications
              </Button>
            </div>
            <div className={s.footerWrapper}>
              <Button onClick={handleClose} className={s.skipBtn}>
                Skip
              </Button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotificationPermissionModal;
