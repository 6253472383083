import React from 'react';

import s from './styles.module.scss';

export const WARNING_OPEN_EXTERNAL_LINK_MODAL_ID: string =
  'WARNING_OPEN_EXTERNAL_LINK_MODAL_ID';

const WarningOpenExternalLinkModal: React.FC<{
  href: string;
  onClose: () => void;
}> = ({ href, onClose }): React.ReactElement => {
  return (
    <div className={s.warningOpenLinkModal}>
      <h6 className={s.title}>Hold up!</h6>
      <div
        className={s.text}
      >{`This link will take you to ${href}. Are you sure you want to go there?`}</div>
      <div className={s.btnBlock}>
        <button className={s.blackBtn} onClick={onClose}>
          Cancel
        </button>
        <a
          href={href}
          target="_blank"
          onClick={onClose}
          className={s.btn}
          rel="noopener noreferrer"
          data-ignore-warring-external
        >
          OK
        </a>
      </div>
    </div>
  );
};

export default React.memo(WarningOpenExternalLinkModal);
