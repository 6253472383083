import {
  Box,
  Button,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import s from './UpdateVersionModal.module.scss';
import accountStorage from '@/utils/account.storage';
import AvatarTwitter from '@/components/AvatarTwitter';
import { formatName } from '@/utils';
import useGameFiWallet from '@/hooks/useGameFiWallet';

type Props = {};

const UpdateVersionModal = (props: Props) => {
  const twitterProfile = accountStorage.getTwitterProfile();

  const { onViewKey } = useGameFiWallet();

  return (
    <Modal isOpen={true} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent p="20px" minW={'90%'} className={s.wrapper}>
        <ModalHeader fontSize="16px" fontWeight={'600'} mb="20px" p="0">
          New version available
        </ModalHeader>
        <ModalBody p={'0'} m="0">
          {twitterProfile && (
            <Box>
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                p={'8px 12px'}
                bgColor={'rgba(255, 255, 255, 0.10)'}
                borderRadius={'8px'}
                className={s.wrapTwitter}
                mb="4px"
              >
                <Flex gap={'8px'} alignItems={'center'}>
                  <AvatarTwitter
                    url={twitterProfile?.twitterAvatar || ''}
                    address={twitterProfile?.twitterUsername || ''}
                    width={40}
                  />
                  <Flex direction={'column'} color={'#000000'} gap={2}>
                    <Flex gap={1} alignItems={'center'}>
                      <Text
                        fontSize={'14px'}
                        lineHeight={'18px'}
                        fontWeight={500}
                      >
                        {formatName(twitterProfile?.twitterName || '', 40)}
                      </Text>
                    </Flex>
                    <Text
                      color={'#000000'}
                      fontSize={'10px'}
                      opacity={'70%'}
                      fontWeight={400}
                    >
                      @{twitterProfile?.twitterUsername}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Text fontSize={'10px'} opacity={0.7} mb="20px">
                This is your current account
              </Text>
              <Text fontSize="12px">
                Please update the app to the latest version.
                <br />
              </Text>
              <UnorderedList className={s.list}>
                <li>Export your private key</li>
                <li>
                  Visit
                  <a href="https://alpha.wtf/" target="_blank">
                    {' '}
                    alpha.wft{' '}
                  </a>
                  to install Alpha v2
                </li>
                <li>
                  Import the private key to continue using Alpha as usual.
                </li>
              </UnorderedList>
              <Button
                w="100%"
                gap="4px"
                h="40px"
                fontSize={'14px'}
                onClick={onViewKey}
                bgColor="white"
                p="0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M10.3335 4V2.5L13.3335 5.5H11.8335C10.7802 5.5 10.3335 5.05333 10.3335 4ZM13.8335 6.5V12C13.8335 13.3333 13.1668 14 11.8335 14H5.8335C4.50016 14 3.8335 13.3333 3.8335 12V4C3.8335 2.66667 4.50016 2 5.8335 2H9.3335V4C9.3335 5.61333 10.2202 6.5 11.8335 6.5H13.8335ZM10.2948 9.80867C10.2694 9.74733 10.2328 9.692 10.1868 9.646L8.85352 8.31266C8.65818 8.11733 8.34149 8.11733 8.14616 8.31266C7.95083 8.508 7.95083 8.82467 8.14616 9.02L8.62614 9.5H6.50016C6.22416 9.5 6.00016 9.724 6.00016 10C6.00016 10.276 6.22416 10.5 6.50016 10.5H8.62614L8.14616 10.98C7.95083 11.1753 7.95083 11.492 8.14616 11.6873C8.24349 11.7847 8.37151 11.834 8.49951 11.834C8.62751 11.834 8.75553 11.7853 8.85286 11.6873L10.1862 10.354C10.2322 10.308 10.2688 10.2527 10.2941 10.1913C10.3454 10.0687 10.3454 9.93133 10.2948 9.80867Z"
                    fill="black"
                  />
                </svg>
                Export Private Key
              </Button>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateVersionModal;
