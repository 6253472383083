import { isBrowser } from '@trustless-computer/dapp-core';
import accountStorage from '@/utils/account.storage';

export const isPermissionGranted = () => {
  if (!isBrowser()) {
    return false;
  }

  if ('Notification' in window) {
    // console.log('Notification.permission: ', Notification.permission);
    if (Notification.permission === 'granted') {
      // You can now send notifications
      return true;
    }
  }
  // accountStorage.removeShowedNotification();
  return false;
};

export const getPermissionStatus = () => {
  if (!isBrowser()) {
    return false;
  }

  if ('Notification' in window) {
    return Notification.permission;
  }
};
