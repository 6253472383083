import { FCM_TOKEN } from '@/constants/storage-key';
import { isBrowser } from '@trustless-computer/dapp-core';

export const getFCMToken = (): string | null => {
  if (isBrowser()) {
    const fcmToken = localStorage.getItem(FCM_TOKEN);
    return fcmToken;
  }
  return null;
};

export const setFCMToken = (token: string): void => {
  if (isBrowser()) {
    localStorage.setItem(FCM_TOKEN, token);
  }
};

export const removeFCMToken = (): void => {
  if (isBrowser()) {
    localStorage.removeItem(FCM_TOKEN);
  }
};
