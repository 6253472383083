import {
  BoostDataList,
  TierType,
  getColorGradientByPercentAndItem,
} from '@/constants/boost';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import s from './style.module.scss';
import cs from 'classnames';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  boostToPercent: number;
};

const BoostLabelModal: React.FC<Props> = ({
  isOpen,
  onClose,
  boostToPercent,
}) => {
  const renderTierRow = (item: TierType) => {
    const { key, values, colors, desc, sub_desc } = item;

    const { bgColor, isAchieve } = getColorGradientByPercentAndItem(
      boostToPercent,
      item
    );

    return (
      <div
        className={s.tierRow}
        key={key}
        style={{
          background: bgColor,
        }}
      >
        <div className={s.group}>
          <p
            className={cs(
              s.tierTitle,
              isAchieve && Number(key) > 2 ? s.tierTitle_isAchieve : null
            )}
          >
            {desc}
          </p>
          <p
            className={cs(
              s.sub_desc,
              isAchieve && Number(key) > 2 ? s.sub_desc_isAchieve : null
            )}
          >
            {`${sub_desc}`}
          </p>
        </div>
        <p
          className={cs(
            s.tierValue,
            isAchieve && Number(key) > 2 ? s.tierValue_isAchieve : null
          )}
        >
          {`${values[0]}%`}
        </p>
      </div>
    );
  };

  return (
    <div className={s.modal}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p="24px 0px" minW={'90%'} textAlign={'center'}>
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            className={s.modal_header}
            textAlign="center"
            fontSize="24px"
            fontWeight="600"
          >
            Boost
          </ModalHeader>
          <ModalBody className={s.content}>
            <div className={s.content}>
              <p className={s.content_label}>Your boost power</p>
              <p className={s.content_value}>{boostToPercent}%</p>
              <p className={s.content_desc}>
                Holding at least 50,000 tokens over time unlocks greater rewards
                for playing games in the community. The longer you hold, the
                better your rewards become.
              </p>

              <p className={s.content_desc1}>Here’s the breakdown:</p>

              <div className={s.tierList}>
                {BoostDataList.map(item => renderTierRow(item))}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default BoostLabelModal;
