import { API_DGAMES } from '@/configs';
import { apiClient } from '@/services';
import { camelCaseKeys } from 'trustless-swap-sdk';

export const getBooster = async ({
  walletAddress,
  tokenAddress,
}: {
  walletAddress: string;
  tokenAddress: string;
}): Promise<any> => {
  try {
    let url = new URL(`${API_DGAMES}/v1/booster/get-factor`);
    url.searchParams.set('walletAddress', walletAddress);
    url.searchParams.set('tokenAddress', tokenAddress);

    // const res = await apiClient.get(
    //   `${API_DGAMES}/v1/booster/get-factor?walletAddress='${walletAddress1}'&tokenAddress='${tokenAddress1}'`
    // );
    const res = await apiClient.get(url.toString());

    return camelCaseKeys(res);
  } catch (error) {
    console.log(error);
    return {};
  }
};
