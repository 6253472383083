import { CDN_URL_ICONS, CDN_URL_IMAGES } from '@/configs';
import Welcome from '@/modules/AlphaPWA/Welcome';
import { NextPage } from 'next';

import { GetServerSideProps } from 'next';
import { setReferralCodeOnServerSide } from '@/utils/referral';
import ModulePageKeys from '@/modules/AlphaPWA/Keys';
import PWALayout from '@/layouts/AlphaLayout';
import AlphaWtfPage from '@/modules/AlphaWtf';

const JackpotDepositPage: NextPage = (): React.ReactElement => {
  return (
    <PWALayout hideNavBar hideHeader>
      <AlphaWtfPage />
    </PWALayout>
  );
};

export const getServerSideProps: GetServerSideProps<any> = async ctx => {
  const code = ctx?.query?.referral;

  if (code) {
    setReferralCodeOnServerSide(ctx, `${code}`);
    // return {
    //   props: {
    //     seoInfo: {
    //       title: 'Alpha',
    //       description: 'Say hello to the friend.tech of Bitcoin',
    //       image: `${CDN_URL_IMAGES}/alpha_metadata_07.png`,
    //       favicon: `${CDN_URL_ICONS}/favi-alpha.svg`,
    //     },
    //   },
    // };
  }

  return {
    props: {
      seoInfo: {
        title: 'Alpha',
        description:
          'Introduce Alpha - the super app that brings crypto to the mainstream without the complexity.',
        image: `${CDN_URL_IMAGES}/home/metadata.png`,
        favicon: `${CDN_URL_ICONS}/favicon-alpha-wtf.svg`,
      },
    },
  };
};

export default JackpotDepositPage;
