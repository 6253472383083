import { TournamentContext } from '@/contexts/tournament-context';
import { WalletContext } from '@/contexts/wallet-context';
import useApi from '@/hooks/useApi';
import { getBooster } from '@/services/booster';
import { useAppDispatch } from '@/state/hooks';
import { closeModal, openModal } from '@/state/modal';
import { useContext } from 'react';
import BoostLabelModal from '../../Profiles/TradeKey/components/profileHeaderUserInfo/BoostLabe_V2/BoostLabelModal';
import { OPENED_BOOST_FINISH_GAME } from '@/constants/storage-key';

const BOOST_MODEL = 'BOOST_MODEL';

export const useOpenBoostModal = () => {
  const dispatch = useAppDispatch();
  const { addressL2 } = useContext(WalletContext);
  const { circleTournamentInfo } = useContext(TournamentContext);

  const { data } = useApi(
    getBooster,
    {
      walletAddress: addressL2,
      tokenAddress: circleTournamentInfo?.potToken,
    },
    { shouldFetch: !!addressL2 && !!circleTournamentInfo?.potToken }
  );

  const boostToPercent = Math.round((data?.factor - 1) * 100);

  const openBoostModal = () => {
    const isOpenedBoost = localStorage.getItem(OPENED_BOOST_FINISH_GAME);

    console.log('isOpenedBoost', isOpenedBoost);

    if (isOpenedBoost == 'true') {
      return;
    }

    dispatch(
      openModal({
        id: BOOST_MODEL,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: true,
        render: () => (
          <BoostLabelModal
            boostToPercent={boostToPercent}
            isOpen={true}
            onClose={() => {
              dispatch(closeModal({ id: BOOST_MODEL }));
            }}
          />
        ),
      })
    );

    localStorage.setItem(OPENED_BOOST_FINISH_GAME, 'true');
  };

  return { openBoostModal };
};
