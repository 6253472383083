import { CDN_URL } from '@/configs';
import { GameFiContext } from '@/contexts/game-fi-context';
import { TournamentContext } from '@/contexts/tournament-context';
import { ClientOs } from '@/enums/client';
import withAlphaMiddleware from '@/hocs/withAlphaMiddleware';
import useContentWindowMessage from '@/hooks/useContentWindowMessage';
import { detectClientOs } from '@/utils/client';
import { delay } from '@/utils/helpers';
import { Spinner, useDisclosure } from '@chakra-ui/react';
import cs from 'classnames';
import React, { useContext, useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ConfirmExitModal from '../ConfirmExitModal';
import { UnityIFrameStatus } from '../constants';
import s from './styles_v2.module.scss';
import AppLoading from '@/components/AppLoading';
import CountDownText from '@/components/CountdownText';
import { useOpenBoostModal } from '../hooks/useOpenBoostModel';

export const PlayScreen: React.FC<{}> = ({}): React.ReactElement => {
  const clientOs = detectClientOs();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onPostMessageCloseGame } = useContentWindowMessage();

  const { setCircleAddress, deadline } = useContext(TournamentContext);

  const { updateIsPlayingGame, unityIframeStatus, isShowBackButton } =
    useContext(GameFiContext);

  useEffect(() => {
    if (isShowBackButton) {
    }
  }, [isShowBackButton]);

  const { openBoostModal } = useOpenBoostModal();

  const openConfirmModal = () => {
    onOpen();
  };

  const iFrame = useMemo(() => {
    var gameUrl = '';
    var hostUrl = window.location.origin;
    gameUrl = `${hostUrl}/unity-launcher`;

    return (
      <iframe id="game_iframe" className={s.gameIframe} src={gameUrl}></iframe>
    );
  }, [unityIframeStatus]);

  // console.log('unityIframeStatus:::,', unityIframeStatus);

  if (unityIframeStatus == UnityIFrameStatus.RELEASED) {
    return <></>;
  }

  return (
    <div
      className={s.playScreenWrapper}
      style={{
        visibility:
          unityIframeStatus == UnityIFrameStatus.PLAYING ||
          unityIframeStatus == UnityIFrameStatus.LOADING
            ? 'visible'
            : 'hidden',
      }}
    >
      <div
        style={{
          visibility:
            unityIframeStatus == UnityIFrameStatus.PLAYING
              ? 'visible'
              : 'hidden',
        }}
      >
        {isShowBackButton ? (
          <Button
            onClick={async () => {
              // openConfirmModal();
              onPostMessageCloseGame();
              updateIsPlayingGame(UnityIFrameStatus.PENDING);
              await delay(3000);
              setCircleAddress();
              openBoostModal();
            }}
            className={cs(s.backBtn, {
              [`${s.ios}`]: clientOs === ClientOs.Ios,
            })}
          >
            <img
              className={s.backIcon}
              src={`${CDN_URL}/icons/chevron-right-ic-32.svg`}
              alt="chevron-right-ic"
            />
            <span>Back</span>
          </Button>
        ) : null}

        {!isShowBackButton && deadline ? (
          <div
            className={cs(s.timerContainer, {
              [`${s.ios}`]: clientOs === ClientOs.Ios,
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="16"
              height="16"
              viewBox="0 0 24 24"
            >
              <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"></path>
            </svg>

            <span>
              <CountDownText deadline={deadline} />
            </span>
          </div>
        ) : (
          <></>
        )}

        {iFrame}
      </div>

      <ConfirmExitModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirmed={async () => {
          setCircleAddress();
          onPostMessageCloseGame();
          updateIsPlayingGame(UnityIFrameStatus.PENDING);
          await delay(3000);
        }}
      />

      <div
        className={s.loadingWrapper}
        style={{
          visibility:
            unityIframeStatus == UnityIFrameStatus.LOADING
              ? 'visible'
              : 'hidden',
        }}
      >
        <div className={s.loadingContainer}>
          {/* <Spinner color="#808080" size={'xl'} /> */}

          <AppLoading />
          <p>Loading game....</p>
        </div>
      </div>
    </div>
  );
};

export default withAlphaMiddleware(PlayScreen);
