import { setKeyboardShown } from '@/state/common';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const Keyboard = ({ children }: PropsWithChildren) => {
  const dispatch = useDispatch();

  if (typeof window === 'undefined' || !window.visualViewport) {
    return <>{children}</>;
  }

  const handleResize = () => {
    if (window.visualViewport) {
      const height = window.innerHeight - window.visualViewport.height;

      document.documentElement.style.setProperty(
        '--keyboard-inset-height',
        `${height}px`
      );
      if (height > 0) {
        dispatch(setKeyboardShown(true));
      } else {
        dispatch(setKeyboardShown(false));
      }
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize, false);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener(
          'resize',
          handleResize,
          false
        );
        dispatch(setKeyboardShown(false));
      }
    };
  }, []);

  return <>{children}</>;
};
