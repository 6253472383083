import React from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import s from './styles.module.scss';
import { useRouter } from 'next/router';
import cs from 'classnames';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: () => void;
};

const ConfirmExitModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirmed,
}: Props): React.ReactElement => {
  const router = useRouter();

  const handleConfirmExit = () => {
    onConfirmed();
    onClose();
  };

  return (
    <div className={s.wrapper}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p="24px 22px" minW={'90%'} textAlign={'center'}>
          <Flex justifyContent={'flex-end'}>
            <ModalCloseButton />
          </Flex>
          <ModalHeader
            textAlign="left"
            fontSize="24px"
            fontWeight={'600'}
            mb="24px"
          >
            Confirm
          </ModalHeader>
          <ModalBody>
            <p className={s.description}>
              Are you sure you want to exit the game?
            </p>
            <div className={s.actionWrapper}>
              <button className={cs(s.button, s.cancelBtn)} onClick={onClose}>
                No
              </button>
              <button
                className={cs(s.button, s.confirmBt)}
                onClick={handleConfirmExit}
              >
                Yes
              </button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ConfirmExitModal;
