import { FIREBASE_MESSAGING_VAPID_KEY } from '@/configs';
import { getFCMToken, setFCMToken } from '@/utils/firebase';
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
  isSupported,
  deleteToken,
} from 'firebase/messaging';
import getFirebaseApp from './base';
import { isBrowser } from '@trustless-computer/dapp-core';
import { sendFCMToken } from '../fcm';
import { INotification } from '@/interfaces/notification';

const parseNotifyDetailData = (payload: MessagePayload) => {
  try {
    const { data } = payload;
    const { detail } = data as any;
    return JSON.parse(atob(detail));
  } catch (e) {
    return {};
  }
};

export const initializeFCM = async (walletAddress: string) => {
  try {
    let messaging: Messaging;

    const supportedBrowser = await isSupported();

    if (!isBrowser() || !supportedBrowser) {
      console.log('Unsupported browser');
      return false;
    }
    const firebaseApp = getFirebaseApp();
    messaging = getMessaging(firebaseApp);

    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.log('rejected permission');
        return false;
      }
    }

    const currentToken = getFCMToken();
    if (!currentToken) {
      try {
        await deleteToken(messaging);
      } catch (err) {
        console.log(err);
      }
    }

    const fcmToken = await getToken(messaging, {
      vapidKey: FIREBASE_MESSAGING_VAPID_KEY,
    });

    if (fcmToken !== currentToken) {
      await sendFCMToken({ token: fcmToken, walletAddress });

      // Save into local storage
      setFCMToken(fcmToken);
    }

    onMessage(messaging, (payload: MessagePayload) => {
      const detail = parseNotifyDetailData(payload);
      console.log('Received a foreground message:', payload, detail);
      const { notification } = payload;

      if (
        detail.type === 'sent_message' &&
        window &&
        window.userState &&
        window.userState === 'Active'
      ) {
        console.log('Cancel receive message on foreground');
        return;
      }
      if (!notification) return;
      const { title, body, icon } = notification as unknown as INotification;
      const notificationTitle = title;
      const notificationOptions = {
        body,
        icon,
      };
      return new Notification(notificationTitle, notificationOptions);
    });

    console.log('initialize fcm successfully');
    return true;
  } catch (err) {
    console.log('Failed to init fcm');
    console.log(err);
    return false;
  }
};
