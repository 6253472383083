import px2rem from '@/utils/px2rem';
import styled from 'styled-components';

export const StyledAbout = styled.div`
  width: 100vw;
  height: 100vh;
  scroll-snap-type: y mandatory;
  -webkit-scroll-snap-type: y mandatory;
  background-color: #000000;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .section-01,
  .section-02,
  .section-03,
  .section-04 {
    position: relative;
    height: 100vh;
    width: 100%;
    /* width: 100vw; */
    display: flex;
    align-items: center;
    justify-content: center;
    scroll-snap-align: start;
    /* scroll-snap-stop: always; */
    -webkit-scroll-snap-align: start;
    /* -webkit-scroll-snap-stop: always; */
  }

  @media screen and (max-width: 768.98px) {
    .section-01,
    .section-02,
    .section-03,
    .section-04 {
      align-items: flex-start;
    }
  }
`;

export const SectionControllers = styled.div`
  position: fixed;
  top: 50%;
  right: ${px2rem(48)};
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${px2rem(12)};

  .dots {
    width: ${px2rem(12)};
    height: ${px2rem(12)};
    display: grid;
    place-items: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background: #646464;

    &.active {
      background: #f8f0ac;
      transition: all 0.3s ease-in-out;
    }
  }

  @media screen and (max-width: 768.98px) {
    display: none;
  }
`;
