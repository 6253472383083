export type TierType = {
  key: string;
  desc: string;
  sub_desc?: string;
  values: number[];
  colors: string[];
};

export const BoostDataList: TierType[] = [
  {
    key: '5',
    desc: 'Tier 5',
    sub_desc: '21-day minimum balance >= 50,000',
    values: [30, 100],
    colors: ['#00F5A0', '#00D9F5'],
  },
  {
    key: '4',
    desc: 'Tier 4',
    sub_desc: '14-day minimum balance >= 50,000',
    values: [20, 30],
    colors: ['#FFE259', '#FFA751'],
  },
  {
    key: '3',
    desc: 'Tier 3',
    sub_desc: '7-day minimum balance >= 50,000',
    values: [10, 20],
    colors: ['#FFE259', '#FFA751'],
  },
  {
    key: '2',
    desc: 'Tier 2',
    sub_desc: '3-day minimum balance >= 50,000',
    values: [5, 10],
    colors: ['#FFFFFF1A', '#FFFFFF1A'],
  },
  {
    key: '1',
    desc: 'Tier 1',
    sub_desc: '',
    values: [0, 5],
    colors: ['#FFFFFF1A', '#FFFFFF1A'],
  },
];

export const getColorGradientByPercent = (percent: number) => {
  let color;
  let index = 0;

  const itemFinded = BoostDataList.find(
    item => percent >= item.values[0] && percent < item.values[1]
  );
  if (itemFinded) {
    color = `linear-gradient(90deg, ${itemFinded.colors[0]}, ${itemFinded.colors[1]})`;
    if (itemFinded.key === '4') {
      index = 1;
    } else if (itemFinded.key === '5') {
      index = 2;
    } else {
      index = 0;
    }
  } else {
    color = `linear-gradient(90deg, ${'#fff'}, ${'#fff'})`;
    index = 0;
  }
  return {
    color,
    index,
  };
};

export const getColorGradientByPercentAndItem = (
  percent: number,
  item: TierType
) => {
  let isAchieve =
    (percent >= item.values[0] && percent < item.values[1]) ||
    (item.key === '5' && percent >= 100);
  let bgColor = isAchieve
    ? `linear-gradient(to left, ${item.colors[0]} ${100}%, ${
        item.colors[1]
      } ${100}%)`
    : 'transparent';

  // if (percent === 100) {
  //   bgColor = `linear-gradient(to left, ${'#00F5A0'} ${100}%, ${'#00D9F5'} ${100}%)`;
  // }

  return {
    isAchieve,
    bgColor,
  };
};
