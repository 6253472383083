import React, { PropsWithChildren, useContext, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
const NavBar = dynamic(() => import('./NavBar'), {
  ssr: false,
});
const Header = dynamic(() => import('./Header'), {
  ssr: false,
});
import { detectClientOs, isWebApp } from '@/utils/client';
import cs from 'classnames';
import { ClientOs } from '@/enums/client';
import s from './styles.module.scss';
import RequiredBackupModal from '@/layouts/AlphaLayout/RequiredBackupModal';
import NotificationPermissionModal from './NotificationPermissionModal';
import accountStorage from '@/utils/account.storage';
// import Referral from '@/modules/AlphaPWA/Referral';
import { initializeFCM } from '@/services/firebase/messaging';
import { isPermissionGranted } from '@/utils/notification';
import { WalletContext } from '@/contexts/wallet-context';
import { useAlphaTheme } from '@/hooks/useAlphaTheme';
import {
  useSocketEvent,
  useSocketProvider,
} from '@/providers/SocketProvider/hooks';
import { useAppDispatch } from '@/state/hooks';
import { updateUnreadNotification } from '@/state/notification/reducer';
import { ALPHA_LAYOUT_MAIN_ID } from '@/layouts/AlphaLayout/constants';
import { Keyboard } from '@/components/Keyboard';
import { RECEIVED_REFERRAL_DONATION } from '@/constants/storage-key';
import { checkIfCanClaim, getListPublicEvents } from '@/services/donate';
import { getAddressReferrer } from '@/services/referral';
import { closeModal, openModal } from '@/state/modal';
import RedEnvelopModal, {
  RED_ENVELOP_MODAL,
} from '@/components/RedEnvelopModal';
import Play from '@/modules/AlphaPWA/Games/Play/index_v2';
import WarningOpenExternalLinkModal, {
  WARNING_OPEN_EXTERNAL_LINK_MODAL_ID,
} from '@/components/WarningOpenExternalLinkModal';
import UpdateVersionModal from './UpdateVersionModal';

const PWALayout: React.FC<
  {
    hideHeader?: boolean;
    hideNavBar?: boolean;
    noLayout?: boolean;
    className?: string;
    mainStyle?: React.CSSProperties;
    hideScrollbar?: boolean;
    isDark?: boolean;
  } & PropsWithChildren
> = ({
  children,
  hideHeader,
  hideNavBar,
  noLayout,
  className = '',
  mainStyle,
  hideScrollbar = false,
  isDark = false,
}): React.ReactElement => {
  const clientOs = detectClientOs();
  const { sendEmit } = useSocketProvider();
  const { addressL2 } = useContext(WalletContext);
  const showedNotification = accountStorage.getNeedShowNotification();
  const newNoti = useSocketEvent<string>('SUBSCRIBE_NOTIFICATION_BY_WALLET');
  const dispatch = useAppDispatch();
  const isPWA = isWebApp();
  const needToRefresh = useRef(false);
  const timeoutIdRef = useRef<null | NodeJS.Timeout>(null);

  const isAuthenticated = !!addressL2 && !!accountStorage.isUserLinkingTwitter;

  const registerNotification = async () => {
    if (!addressL2) {
      return;
    }

    try {
      sendEmit('SUBSCRIBE_ADDRESS', addressL2);
    } catch (err) {
      console.log('emitSocketEvent error', err);
    }
  };

  const checkIfDiscarded = () => {
    if ('wasDiscarded' in document) {
      if (document.wasDiscarded) {
        // The PWA was discarded from memory
        // console.log('PWA was discarded from memory');
        // Perform actions to reload the previous state or restore the app
        window.location.reload();
      }
    }
  };

  const handleVisibilityChange = () => {
    // The PWA is minimized
    if (document.visibilityState === 'hidden') {
      // Start a countdown
      timeoutIdRef.current = setTimeout(() => {
        // Hidden for over 5 mins, need to refresh
        needToRefresh.current = true;
      }, 300_000);
    }
    // The PWA is in the foreground
    else {
      // console.log('PWA is in the foreground');

      // Android --- so easy to handle
      if (clientOs === ClientOs.Android) {
        checkIfDiscarded();
      }
      // IOS --- hmm...
      else {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }
        if (needToRefresh.current) {
          window.location.reload();
        }
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModal({ id: RED_ENVELOP_MODAL }));
  };

  const handleShowLuckyMoney = async () => {
    const isReceived = localStorage.getItem(RECEIVED_REFERRAL_DONATION);
    if (isReceived) return;
    const res = await getAddressReferrer();
    if (!res || !addressL2) return;
    const availableEvents = await getListPublicEvents({
      network: 'nos',
      address: addressL2 || '',
      playerAddress: res,
    });
    if (availableEvents && availableEvents.length > 0) {
      const canClaim = await checkIfCanClaim({
        address: addressL2 || '',
        playerAddress: res,
        network: 'nos',
        eventId: availableEvents[0].eventId,
      });
      if (!canClaim) {
        return;
      }
      dispatch(
        openModal({
          id: RED_ENVELOP_MODAL,
          theme: 'dark',
          modalProps: {
            centered: true,
            zIndex: 9999999,
          },
          className: 'donate_modal',
          hideCloseButton: true,
          render: () => (
            <RedEnvelopModal
              event={availableEvents[0]}
              onClose={handleCloseModal}
            />
          ),
        })
      );
    }
  };

  const handleOpenExternalURL = (href: string) => {
    if (!href) return;

    // show modal
    dispatch(
      openModal({
        id: WARNING_OPEN_EXTERNAL_LINK_MODAL_ID,
        theme: 'dark',
        modalProps: {
          centered: true,
          zIndex: 9999999,
        },
        hideCloseButton: true,
        render: () => (
          <WarningOpenExternalLinkModal
            href={href}
            onClose={() => {
              dispatch(closeModal({ id: WARNING_OPEN_EXTERNAL_LINK_MODAL_ID }));
            }}
          />
        ),
      })
    );
  };

  const isExternalURL = (url: string) => {
    try {
      return new URL(url).origin !== location.origin;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(handleShowLuckyMoney, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [addressL2]);

  useEffect(() => {
    if ('visibilityState' in document && isPWA) {
      // Handle page visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange);
      // Initial check when the page loads
      checkIfDiscarded();
    }

    const observer = new MutationObserver(() => {
      const anchorList = document.getElementsByTagName('a');
      for (const key in anchorList) {
        if (Object.prototype.hasOwnProperty.call(anchorList, key)) {
          const element = anchorList[key];
          // is skip ?
          if (
            element?.hasAttribute('data-ignore-warring-external')
            // ||
            // element?.href.startsWith('https://giphy.com/gifs/')
          )
            continue;
          // let's handle
          element.addEventListener('click', evt => {
            evt?.stopPropagation();

            if (
              element?.href &&
              isExternalURL(element?.href) &&
              element?.hasAttribute('data-check-warning-external') === false
            ) {
              evt?.preventDefault();
              handleOpenExternalURL(element?.href);
              element.setAttribute('data-check-warning-external', 'true');
            }
          });
        }
      }
    });
    observer.observe(document.body, {
      subtree: true,
      characterData: true,
      childList: true,
    });

    return () => {
      if ('visibilityState' in document && isPWA) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
      // Stop observing external link
      observer && observer?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (newNoti) {
      const notiObj = JSON.parse(window.atob(newNoti)) as any;
      dispatch(
        updateUnreadNotification({
          notifications: [notiObj],
        })
      );
    }
  }, [newNoti]);

  useEffect(() => {
    registerNotification();
  }, [addressL2]);

  useAlphaTheme('dark');

  useEffect(() => {
    if (isPermissionGranted() && addressL2) {
      accountStorage.setShowedNotification();
      initializeFCM(addressL2);
    }
  }, [addressL2]);

  return (
    <div
      className={cs(s.fontWrapper, {
        [s.fontWrapper__hideScrollbar]: hideScrollbar,
      })}
    >
      <Keyboard>
        {hideHeader ? null : <Header />}
        {noLayout ? (
          <main
            id={ALPHA_LAYOUT_MAIN_ID}
            style={mainStyle}
            className={cs(
              {
                [`${s.hideHeader}`]: hideHeader,
              },
              className
            )}
          >
            {children}
          </main>
        ) : (
          <main
            id={ALPHA_LAYOUT_MAIN_ID}
            style={mainStyle}
            className={cs(
              s.main,
              {
                [`${s.ios}`]: clientOs === ClientOs.Ios,
                [`${s.hideHeader}`]: hideHeader,
                [`${s.hideNavBar}`]: hideNavBar,
                [`${s.main__dark}`]: isDark,
              },
              className
            )}
          >
            {children}
          </main>
        )}
        {hideNavBar ? null : <NavBar />}
        <RequiredBackupModal />
        {isAuthenticated && !showedNotification && (
          <NotificationPermissionModal />
        )}
        <UpdateVersionModal />
      </Keyboard>

      <Play />
      {/* <NotificationPermissionModal /> */}
    </div>
  );
};

export default PWALayout;
